
export const matches = {
    "error": null,
    "data": [
        {
            "id": "all",
            "name": "전체"
        },
        {
            "id": "soccer",
            "name": "축구",
            "count": 225
        },
        {
            "id": "esports",
            "name": "E-스포츠",
            "count": 13
        },
        {
            "id": "baseball",
            "name": "야구",
            "count": 39
        },
        {
            "id": "basketball",
            "name": "농구",
            "count": 54
        },
        {
            "id": "volleyball",
            "name": "배구",
            "count": 18
        },
        {
            "id": "tabletennis",
            "name": "탁구",
            "count": 597
        },
        {
            "id": "icehockey",
            "name": "하키",
            "count": 0
        },
        {
            "id": "americanfootball",
            "name": "미식축구",
            "count": 0
        },
        {
            "id": "boxing",
            "name": "UFC/복싱",
            "count": 0
        },
        {
            "id": "handball",
            "name": "핸드볼",
            "count": 0
        }
    ]
}