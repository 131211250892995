import {useEffect, useState} from "react";
import {matches} from "../../assets/data/match";

const Left = ({onSelectLeague}) => {
    const [selectedMatch, setSelectedMatch] = useState(matches.data[0]);

    useEffect(() => {
        // 초기 로딩시 첫번째 종목 선택
        onMatch(matches.data[0]);
    }, []);

    const onMatch = (match) => {
        setSelectedMatch(match);
        onSelectLeague({
            id: match.id,
            name: match.name
        });
    }

    return (
        <div className="w-full">
            <div className="overflow-x-auto">
                <div className="flex flex-row bg-white rounded shadow-sm md:gap-2 gap-2 min-w-min">
                    {matches.data.map((match, idx) => {
                        const isSelected = match.id === selectedMatch.id;
                        return (
                            <button
                                key={String(idx)}
                                className={`flex-shrink-0 flex items-center gap-2 md:px-4 px-3 py-1 rounded-lg transition-colors whitespace-nowrap ${
                                    isSelected
                                        ? "bg-blue-500 text-white"
                                        : "text-gray-700 hover:bg-gray-50"
                                }`}
                                onClick={() => onMatch(match)}
                            >
                                <span className="font-medium">{match.name}</span>
                            </button>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default Left;