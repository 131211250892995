import {useEffect, useState} from "react";
import {TrashIcon} from "@heroicons/react/24/outline";
import numeral from "numeral";
import BettingRow from "./BettingRow";
import {calcOdds} from "../biz/Betting";
import {accountActions} from "../../redux/actions/account";
import {connect, useSelector} from "react-redux";
import * as BizUtil from "../../util/BizUtil"

const Right = (props) => {
    const {user} = useSelector((props) => props.account);
    const [bettingInfo, setBettingInfo] = useState({
        minimumBet: 5000, maximumBet: 10000000, maximumWinning: 999999999, expectWinning: 0
    })
    const [bettingPrice, setBettingPrice] = useState(0)
    const [oddPer, setOddPer] = useState(1)

    useEffect(() => {
        props.onBettingAmount(bettingPrice)
        calc()
    }, [bettingPrice])

    useEffect(() => {
        // 배팅정보를 기반으로 계산
        const bettingList = props.bettingList;
        if (bettingList.length > 0) {
            // 배당율 계산
            const oddsPer = calcOdds(bettingList)
            setOddPer(oddsPer)
        }
        else {
            setOddPer(1)
            setBettingPrice(0)
            // 배팅 목록이 비워질 때 예상당첨금액도 초기화
            setBettingInfo(prev => ({
                ...prev,
                expectWinning: 0
            }))
        }
    }, [props.bettingList])

    const init = () => {
        setBettingPrice(0)
        setOddPer(1)
        // 초기화 시 예상당첨금액도 초기화
        setBettingInfo(prev => ({
            ...prev,
            expectWinning: 0
        }))
        props.init()
    }

    const bettingAmount = (amount) => {
        const {info} = props;
        let totalAmount = bettingPrice + parseInt(amount);
        if (totalAmount > user.userPoint) {
            // 내가 가지고 있는 금액 이상은 안됨
            totalAmount = user.userPoint
        }
        setBettingPrice(totalAmount)
    }

    const clear = () => {
        setBettingPrice(0)
        // 금액 초기화 시 예상당첨금액도 초기화
        setBettingInfo(prev => ({
            ...prev,
            expectWinning: 0
        }))
    }

    const calc = () => {
        // 예상 당첨금액을 구해주자
        let expectAmount = Math.round(bettingPrice * oddPer * 100) / 100;
        // 예상 금액이 최대 금액을 넘으면 최대 금액으로
        if (expectAmount > bettingInfo.maximumWinning) {
            expectAmount = bettingInfo.maximumWinning
        }
        setBettingInfo(prev => ({
            ...prev,
            expectWinning: expectAmount
        }))
    }

    const onBetting = () => {
        if (bettingPrice <= 0) {
            window.alert("배팅 금액을 입력해주세요.")
            return
        }
        props.onBetting()
    }

    const onDelete = (idx) => {
        props.onBettingRowDelete(idx)
    }

    return (
        <div className={"w-full flex flex-col p-2 bg-white border-l border-gray-200"}>
            <div className={"flex flex-row p-2 justify-between bg-gray-50 rounded-lg border border-gray-200"}>
                <div className={"flex flex-row gap-2 items-center"}>
                    <span className={"text-gray-600 text-sm"}>전체</span>
                    <span className={"text-blue-600 text-sm font-bold"}>{props.bettingList.length}</span>
                </div>
                <button className={"rounded text-gray-600 hover:text-gray-800"} onClick={init}>
                    <TrashIcon className="h-4 w-4" />
                </button>
            </div>
            <div className={"md:h-[280px] flex flex-col py-2 mb-2 items-center overflow-y-auto"}>
            {props.bettingList.length > 0 &&
                <div className={"flex flex-col w-full gap-2"}>
                    {props.bettingList.map((item, idx) => {
                        return (
                            <BettingRow key={String(idx)} idx={idx} item={item} onDelete={onDelete} />
                        )
                    })}
                </div>
            }
            </div>
            <div className={"flex flex-col bg-white"}>
                <div className={"flex flex-col mb-2 divide-y divide-gray-100 text-[13px] md:text-sm border"}>
                    <InfoRow label="배당률" value={`x ${oddPer}`} />
                    <InfoRow label="최소배팅금액" value={`${numeral(bettingInfo.minimumBet).format("0,0")} 원`} />
                    <InfoRow label="최대배팅금액" value={`${numeral(bettingInfo.maximumBet).format("0,0")} 원`} />
                    <InfoRow label="최대당첨금액" value={`${numeral(bettingInfo.maximumWinning).format("0,0")} 원`} />
                    <InfoRow label="예상당첨금액" value={`${numeral(bettingInfo.expectWinning).format("0,0")} 원`} />
                </div>
                <div className={"flex flex-row p-2 items-center bg-gray-100 mb-2"}>
                    <span className={"text-xs text-gray-700"}>배팅금액</span>
                    <input 
                        className={"flex-1 px-2 text-end bg-transparent text-blue-600 text-sm font-bold focus:outline-none"}
                        onChange={(e) => {
                            const amount = Number(BizUtil.removeComma(e.target.value))
                            setBettingPrice(amount)
                        }}
                        value={numeral(bettingPrice).format("0,0")}
                    />
                </div>
                <div className={"flex flex-col gap-1 mb-3"}>
                    <div className={"grid grid-cols-4 md:grid-cols-3 gap-1"}>
                        <AmountButton amount={5000} onClick={() => bettingAmount(5000)} />
                        <AmountButton amount={10000} onClick={() => bettingAmount(10000)} />
                        <AmountButton amount={50000} onClick={() => bettingAmount(50000)} />
                    </div>
                    <div className={"grid grid-cols-4 md:grid-cols-3 gap-1"}>
                        <AmountButton amount={100000} onClick={() => bettingAmount(100000)} />
                        <AmountButton amount={300000} onClick={() => bettingAmount(300000)} />
                        <AmountButton amount={500000} onClick={() => bettingAmount(500000)} />
                    </div>
                    <div className={"grid grid-cols-3 gap-1"}>
                        <ActionButton text="초기화" onClick={clear} />
                        <ActionButton text="최대금액" onClick={() => bettingAmount(99999999)} />
                        <ActionButton text="최소금액" onClick={() => bettingAmount(5000)} />
                    </div>
                </div>
                <button 
                    className={"py-2 bg-blue-500 hover:bg-blue-600 rounded-lg text-white text-sm font-bold transition-colors"}
                    onClick={onBetting}
                >
                    배팅하기
                </button>
            </div>
        </div>
    )
}

const InfoRow = ({ label, value }) => (
    <div className={"flex flex-row p-2 items-center justify-between"}>
        <span className={"text-xs text-gray-600"}>{label}</span>
        <span className={"text-xs text-blue-600 font-bold"}>{value}</span>
    </div>
);

const AmountButton = ({ amount, onClick }) => (
    <button 
        className={"py-2 text-gray-700 text-xs bg-gray-50 hover:bg-gray-100 border border-gray-200 transition-colors"} 
        onClick={onClick}
    >
        {numeral(amount).format("0,0")}
    </button>
);

const ActionButton = ({ text, onClick }) => (
    <button 
        className={"py-2 text-gray-700 text-xs bg-gray-50 hover:bg-gray-100 border border-gray-200 transition-colors"} 
        onClick={onClick}
    >
        {text}
    </button>
);

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        account
    }
}

export default connect(mapStateToProps, null)(Right)