import {changeSportName} from "../../biz/Betting";

const WidgetBoardRow = (props) => {
    const {notice} = props
    const {instTime, subject} = props.data

    return (
        <div className={"flex flex-row p-2 border-b border-gray-100 items-center justify-between hover:bg-gray-50 transition-colors"}>
            <div className={"flex flex-row items-center gap-2 flex-1"}>
                <span className={"text-blue-500 flex-shrink-0"}>•</span>
                <span className={"text-sm text-gray-700 truncate"}>{subject}</span>
            </div>

            {!notice && (
                <span className={"text-sm text-gray-500 flex-shrink-0 ml-4"}>{instTime}</span>
            )}
        </div>
    )
}

export default WidgetBoardRow