import numeral from "numeral";
import dayjs from "dayjs";

const WidgetWithdrawRow = ({data, index}) => {
    const {updTime, amount, userId} = data;
    
    return (
        <div className={"grid grid-cols-[80px_120px_1fr_100px] items-center py-2 border-b border-gray-100 hover:bg-gray-50 transition-colors"}>
            {/* 순위 */}
            <span className={"text-sm text-gray-600 text-center"}>
                {index + 1}
            </span>
            
            {/* 시간 */}
            <span className={"text-sm text-gray-600 text-center"}>
                {dayjs(updTime).format('YYYY-MM-DD')}
            </span>
            
            {/* 금액 */}
            <span className={"text-sm text-blue-600 font-medium text-right"}>
                {numeral(amount).format('0,0')}
            </span>
            
            {/* 회원 ID */}
            <span className={"text-sm text-gray-600 text-center"}>
                {userId}
            </span>
        </div>
    )
}

export default WidgetWithdrawRow;