const MenuButton = (props) => {
    const {id, img, title, desc, onClick} = props
    return (
        <div className={"flex flex-col aspect-[3/2] rounded-lg border border-gray-200 p-3 items-center justify-center gap-1 hover:bg-blue-50 hover:border-blue-500 transition-all duration-200 cursor-pointer shadow-sm"}
             onClick={() => onClick(id)}>
            <span className={"text-gray-800 font-bold text-sm md:text-base truncate w-full text-center"}>{title}</span>
            <span className={"text-gray-500 text-xs md:text-sm truncate w-full text-center"}>{desc}</span>
        </div>
    )
}

export default MenuButton