import {useEffect, useCallback} from "react";
import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { TagIcon } from "@heroicons/react/24/outline";
import { LockOpenIcon } from "@heroicons/react/24/outline";
import { BanknotesIcon } from "@heroicons/react/24/outline";
import { ArrowPathRoundedSquareIcon } from "@heroicons/react/24/outline";
import { ShieldExclamationIcon } from "@heroicons/react/24/outline";
import {accountActions} from "../../../redux/actions/account";
import {connect, useSelector} from "react-redux";
import * as Global from "../../biz/Global"
import numeral from "numeral";
import * as api from "../../../api/api";

const UserLogin = (props) => {
    const {user} = useSelector((props) => props.account);

    const onPoint = () => {

    }

    const onCoupon = () => {

    }

    const logout = () => {
        // 로그아웃 처리 수정
        props.setAccount({})  // Redux store 초기화
        props.setLogin(false) // 로그인 상태 변경
        props.onMenu(0)       // 메뉴 초기화
    }

    // 사용자 정보 갱신 함수
    const fetchUserInfo = useCallback(async () => {
        if (user?.userId) {
            try {
                const response = await api.getGUser(user.userId);
                if (response?.status === 200 && response?.data) {
                    console.log("fetchUserInfo", response.data)
                    props.setAccount(response.data);
                }
            } catch (error) {
                console.error('사용자 정보 갱신 실패:', error);
            }
        }
    }, [user?.userId]);

    useEffect(() => {
        // user 정보가 없으면 로그아웃 상태로 변경
        if (!user || Object.keys(user).length === 0) {
            props.setLogin(false)
            return;
        }
        
    }, [user, fetchUserInfo])

    return (
        <div className={"flex flex-row justify-center w-full bg-white"}>
            <div className={"w-full max-w-[1280px] flex flex-col md:flex-row items-start md:items-center justify-end p-2 px-2 gap-2 md:gap-3 border-b border-gray-200"}>
                <div className={"flex flex-row items-center gap-3 w-full md:w-auto justify-between md:justify-start"}>
                    <span className={"text-sm text-gray-700 whitespace-nowrap"}>{user.nickName}</span>
                    <div className={"flex flex-row gap-1 items-center"}>
                        <EnvelopeIcon className="h-4 w-4 text-gray-500" />
                        <span className={"text-sm text-gray-700"}>0</span>
                    </div>
                </div>

                <div className={"flex flex-row items-center gap-3 w-full md:w-auto justify-between md:justify-start"}>
                    <button className={"flex flex-row gap-1 items-center"} onClick={() => props.onMenu(Global.MENU_CHARGE)}>
                        <span className={"text-sm text-gray-700"}>보유금액</span>
                        <span className={"text-sm text-blue-600"}>{numeral(user.userCash).format("0,0")} 원</span>
                    </button>
                    <button className={"flex flex-row gap-1 items-center"} onClick={onPoint}>
                        <span className={"text-sm text-gray-700"}>포인트</span>
                        <span className={"text-sm text-blue-600"}>{numeral(user.userPoint).format("0,0")}p</span>
                    </button>
                </div>

                <div className={"flex flex-row items-center gap-2 w-full md:w-auto justify-between md:justify-start"}>
                    <button className={"flex flex-row gap-1 items-center"} onClick={logout}>
                        <LockOpenIcon className="h-4 w-4 text-gray-500" />
                        <span className={"text-sm text-gray-700"}>로그아웃</span>
                    </button>
                    <div className={"flex flex-row gap-1 items-center"}>
                        <button className={"flex flex-row gap-1 items-center px-2"} onClick={() => props.onMenu(Global.MENU_CHARGE)}>
                            <BanknotesIcon className="h-4 w-4 text-gray-600" />
                            <span className={"text-sm text-gray-700"}>충전</span>
                        </button>
                        <button className={"flex flex-row gap-1 items-center px-2 border-l border-gray-300"} onClick={() => props.onMenu(Global.MENU_WITHDRAW)}>
                            <ArrowPathRoundedSquareIcon className="h-4 w-4 text-gray-600" />
                            <span className={"text-sm text-gray-700"}>환전</span>
                        </button>
                        <button className={"flex flex-row gap-1 items-center px-2 border-l border-gray-300"} onClick={() => props.onMenu(Global.MENU_EXCHANGE_POINT)}>
                            <ShieldExclamationIcon className="h-4 w-4 text-gray-600" />
                            <span className={"text-sm text-gray-700"}>포인트전환</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        account
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAccount: (account) => {
            dispatch(accountActions.setAccount(account));
        },
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserLogin)