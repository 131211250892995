import BettingGuideSoccer from "./guide/BettingGuideSoccer";

const BettingGuide = (props) => {
    return (
        <div className={"w-[1140px] flex flex-col bg-white"}>
            <img src={"https://14-ca.com/img/lang/kr/img_title_guide.jpg"} alt={""}/>
            <div className={"flex flex-row px-2 py-4 justify-between border-b"}>
                <div className={"flex flex-row"}>
                    <span className={"text-sm text-gray-500"}>배팅규정 {">>"} 스포츠게임</span>
                </div>
            </div>
            <div className={"flex flex-col items-center"}>
                <span className={"text-lg font-normal"}>라이브스포츠 배팅</span>
                <span className={"text-sm"}>Live Betting</span>
            </div>

            <div className={"flex flex-col p-4 mb-4"}>
                <div className={"flex flex-row bg-gray-400"}>
                    <span className={"w-[153px] py-2 text-sm text-center"}>배팅타입</span>
                    <span className={"w-[76px] py-2 text-sm text-center"}>적용시간</span>
                    <span className={"w-[864px] py-2 text-sm text-center"}>룰</span>
                </div>
                <BettingGuideSoccer />
            </div>
        </div>
    )
}

export default BettingGuide