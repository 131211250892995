import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const RollingBanner = (props) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        adaptiveHeight: true
    }

    return (
        <div className={"w-full aspect-[5/3] rounded-xl border border-gray-200 overflow-hidden"}>
            <Slider {...settings} className={"w-full h-full"}>
                {/* 슬라이더 내용 */}
            </Slider>
        </div>
    )
}

export default RollingBanner