import Header from "./header/Header";
import Contents from "./Contents";
import UserLogout from "./user/UserLogout";
import UserLogin from "./user/UserLogin";
import {useEffect, useState} from "react";
import {accountActions} from "../../redux/actions/account";
import {connect, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import * as api from "../../api/api";
import PointExchange from "./modal/PointExchange";
import {CModal} from "@coreui/react";
import * as Global from "../biz/Global";

const Frame = (props) => {
    const {user} = useSelector((props) => props.account);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [isLogin, setIsLogin] = useState(false)
    const [menuIdx, setMenuIdx] = useState(0)
    const [userInfo, setUserInfo] = useState({})
    const [pointExchangeOpen, setPointExchangeOpen] = useState(false)

    useEffect(() => {
        if (!user || Object.keys(user).length === 0) {
            setIsLogin(false)
            setUserInfo({})
            setMenuIdx(0)
        } else {
            setIsLogin(true)
            setUserInfo(user)
        }
    }, [user])

    const setLogin = async (value, userData = null) => {
        try {
            if (value && userData) {
                await props.setAccount(userData)
                setIsLogin(true)
                setUserInfo(userData)
            } else {
                props.setAccount({})
                setIsLogin(false)
                setUserInfo({})
                setMenuIdx(0)
            }
        } catch (error) {
            console.error('Login state change error:', error)
            window.alert('로그인 상태 변경 중 오류가 발생했습니다.')
        }
    }

    const onMenu = (idx) => {
        if (!isLogin && idx !== 0) {
            window.alert("로그인이 필요한 서비스입니다.")
            return
        }

        if (idx === Global.MENU_EXCHANGE_POINT) {
            setPointExchangeOpen(true)
            return
        }

        setMenuIdx(idx)
    }

    return (
        <div className="flex flex-col h-screen bg-gray-50">
            {/* 헤더 영역 - 고정 */}
            <div className="flex-none w-full bg-white relative z-10">
                <Header onMenu={onMenu} />
                {!isLogin &&
                    <UserLogout setLogin={setLogin} onMenu={onMenu} />
                }
                {isLogin &&
                    <UserLogin setLogin={setLogin} onMenu={onMenu} />
                }
            </div>

            {/* 컨텐츠 영역 - 스크롤 가능 */}
            <div className="flex-1 min-h-0">
                <div className="h-full overflow-y-auto">
                    <div className="flex justify-center">
                        <div className="w-full max-w-[1280px] px-2">
                            <Contents menuIdx={menuIdx} onMenu={onMenu} />
                        </div>
                    </div>
                </div>
            </div>

            {/* 모달 */}
            <CModal backdrop={"static"}
                    alignment={"center"}
                    visible={pointExchangeOpen}>
                <PointExchange title={"포인트 전환"}
                             content={"포인트 전환"}
                             onClose={() => setPointExchangeOpen(false)} />
            </CModal>
        </div>
    )
}

const mapStateToProps = (state) => {
    const { account } = state;
    return {
        account
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAccount: (account) => {
            dispatch(accountActions.setAccount(account));
        },
        setMyIp: (ip) => {
            dispatch(accountActions.setMyIp(ip))
        },
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Frame)