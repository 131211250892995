import {useEffect, useState} from "react";
import Loading from "../../component/Loading";
import * as api from "../../api/api";
import React from "react";

const FreeMatch = ({ id, name, onCart }) => {
    const [loading, setLoading] = useState(false);
    const [contentsList, setContentsList] = useState({});
    const [expandedMatches, setExpandedMatches] = useState({});

    useEffect(() => {
        console.log("FreeMatch useEffect", id)
        if (id) {
            getMatchList(id);
            // 여러 스크롤 컨테이너에 대한 처리 추가
            setTimeout(() => {
                // 직접적인 스크롤 컨테이너
                const scrollContainer = document.querySelector('.min-h-\\[calc\\(100vh-200px\\)\\]');
                if (scrollContainer) {
                    scrollContainer.scrollTop = 0;
                }
                
                // 부모 스크롤 컨테이너들도 함께 처리
                const parentContainers = document.querySelectorAll('.overflow-y-auto, .overflow-auto');
                parentContainers.forEach(container => {
                    container.scrollTop = 0;
                });

                // window 스크롤도 처리
                window.scrollTo(0, 0);
            }, 200);
        }
    }, [id])

    const getMatchList = (matchId) => {
        setLoading(true);
        api.getMatchList(matchId).then(res => {
            const {data, status} = res;
            if (status === 200) {
                const groupData = groupDataByLeague(data);
                setContentsList(groupData);
            }
            else {
                window.alert("API 서버 통신 에러");
            }
        })
        .catch(ex => {
            window.alert(ex.message);
        })
        .finally(() => {
            setLoading(false);
        })
    }

    const groupDataByLeague = (data) => {
        // 리그와 날짜/시간대로 그룹화
        const groupedData = data.reduce((acc, item) => {
            const [date, time] = item.time.split(' ');
            const formattedDate = date.replace(/\./g, '-');  // 날짜 형식 통일
            const hour = time.split(':')[0];
            const minute = time.split(':')[1];
            const timeKey = `${hour}:${minute}`;
            
            // 시간, 국가, 리그 순으로 키 생성
            const key = `${formattedDate}_${timeKey}_${item.cc_kr}_${item.league_name}`;
            
            if (!acc[key]) {
                acc[key] = {
                    date: formattedDate,
                    time: timeKey,
                    cc_kr: item.cc_kr,
                    league_id: item.league_id,
                    league_name: item.league_name,
                    league_image: item.league_image,
                    cc_image: item.cc_image,
                    matches: []
                }
            }
            acc[key].matches.push(item);
            return acc;
        }, {});

        // 시간, 국가, 리그 순으로 정렬
        const sortedKeys = Object.keys(groupedData).sort((a, b) => {
            const [dateA, timeA, countryA, leagueA] = a.split('_');
            const [dateB, timeB, countryB, leagueB] = b.split('_');
            
            // 날짜 비교
            const dateCompare = dateA.localeCompare(dateB);
            if (dateCompare !== 0) return dateCompare;
            
            // 시간 비교 (HH:MM 형식)
            const timeCompare = timeA.localeCompare(timeB);
            if (timeCompare !== 0) return timeCompare;
            
            // 국가 비교
            const countryCompare = countryA.localeCompare(countryB);
            if (countryCompare !== 0) return countryCompare;
            
            // 리그명 비교
            return leagueA.localeCompare(leagueB);
        });

        // 정렬된 순서로 새로운 객체 생성
        return sortedKeys.reduce((acc, key) => {
            // matches를 정확한 시간순으로 정렬
            const sortedMatches = [...groupedData[key].matches].sort((a, b) => {
                const timeA = new Date(a.time.replace(/\./g, '-'));
                const timeB = new Date(b.time.replace(/\./g, '-'));
                return timeA - timeB;
            });
            
            acc[key] = {
                ...groupedData[key],
                matches: sortedMatches
            };
            return acc;
        }, {});
    };

    const handleOddsClick = (match, odds, type) => {
        if (!odds) return;

        let oddValue;
        let oddName;

        switch(type) {
            case 'home':
                oddValue = odds.oddsHomeValue;
                oddName = odds.oddsHomeName;
                break;
            case 'draw':
                oddValue = odds.oddsDrawValue;
                oddName = odds.oddsDrawName;
                break;
            case 'away':
                oddValue = odds.oddsAwayValue;
                oddName = odds.oddsAwayName;
                break;
            default:
                return;
        }

        const bettingData = {
            id: match.id,
            league_name: match.league_name,
            sport: match.sport,
            market_id: odds.marketId,
            market_name: odds.marketName,
            odd_name: oddName,
            odd_value: parseFloat(oddValue),
            odds_base: odds.oddsBase || "0"
        };

        onCart(bettingData);
    };

    const toggleMatchExpand = (matchId) => {
        setExpandedMatches(prev => ({
            ...prev,
            [matchId]: !prev[matchId]
        }));
    };

    // 재사용 가능한 배당 버튼 컴포넌트
    const OddsButton = ({ name, value, onClick, type, position, isSelected }) => (
        <div 
            className={`flex flex-1 min-w-0 flex-row p-2 text-center border border-gray-200 rounded-lg items-center cursor-pointer transition-all min-h-[42px]
                ${isSelected ? 'bg-red-500 hover:bg-red-600' : 'hover:bg-blue-50 hover:border-blue-300'}`}
            onClick={onClick}
        >
            {type === 'unover' ? (
                position === 'left' ? (
                    <div className="flex flex-1 items-center justify-between">
                        <div className="flex-1 min-w-0 text-left">
                            <div className={`text-xs md:text-sm font-bold ${isSelected ? 'text-white' : 'text-gray-600'}`}>
                                오버
                            </div>
                        </div>
                        <div className="flex items-center gap-1">
                            <span className={isSelected ? 'text-white' : 'text-red-500'}>▲</span>
                            <span className={`font-bold ${isSelected ? 'text-white' : 'text-blue-600'}`}>{value}</span>
                        </div>
                    </div>
                ) : (
                    <div className="flex flex-1 items-center justify-between">
                        <div className="flex items-center gap-1">
                            <span className={`font-bold ${isSelected ? 'text-white' : 'text-blue-600'}`}>{value}</span>
                            <span className={isSelected ? 'text-white' : 'text-blue-500'}>▼</span>
                        </div>
                        <div className="flex-1 min-w-0 text-right">
                            <div className={`text-xs md:text-sm font-bold ${isSelected ? 'text-white' : 'text-gray-600'}`}>
                                언더
                            </div>
                        </div>
                    </div>
                )
            ) : (
                position === 'left' ? (
                    <>
                        <div className="flex-1 min-w-0 text-left">
                            <div className="text-xs md:text-sm text-gray-600 font-bold truncate">
                                {name}
                            </div>
                        </div>
                        <div className="flex-shrink-0 ml-2 font-bold text-blue-600">
                            {value}
                        </div>
                    </>
                ) : (
                    <>
                        <div className="flex-shrink-0 mr-2 font-bold text-blue-600">
                            {value}
                        </div>
                        <div className="flex-1 min-w-0 text-right">
                            <div className="text-xs md:text-sm text-gray-600 font-bold truncate">
                                {name}
                            </div>
                        </div>
                    </>
                )
            )}
        </div>
    );

    // Draw 배당 버튼 컴포넌트
    const DrawButton = ({ value, oddsBase, type, onClick, isSelected }) => (
        <div className="flex-shrink-0 w-16 md:w-20 p-2 text-center border border-gray-200 rounded-lg flex items-center justify-center min-h-[42px]">
            {type === 'basic' ? (
                // 기본 배당의 경우 기존 로직 유지
                value ? (
                    <div 
                        className="hover:bg-blue-50 hover:border-blue-300 cursor-pointer transition-all rounded w-full"
                        onClick={onClick}
                    >
                        <div className="font-bold text-sm text-blue-600">{value}</div>
                    </div>
                ) : (
                    <div className="text-sm text-gray-400">VS</div>
                )
            ) : (
                // 추가 배당(핸디캡, 언더오버)의 경우 항상 oddsBase만 표시
                oddsBase && (
                    <div className="text-sm font-medium text-gray-500">
                        {oddsBase}
                    </div>
                )
            )}
        </div>
    );

    const renderMatchRow = (match, odds, type = 'basic', showHeader = true) => (
        <div className="p-2">
            {showHeader && (
                <div className="flex items-center justify-between mb-2">
                    <div className="flex items-center gap-2">
                        <span className="text-sm font-medium text-gray-600">
                            {match.time}
                        </span>
                        <span className="text-sm text-gray-600">
                            {type === 'handy' ? '핸디캡' : 
                             type === 'unover' ? '언더오버' : 
                             odds.marketName}
                        </span>
                        <span className="text-sm text-gray-600">
                            {match.id}
                        </span>
                    </div>
                </div>
            )}

            {/* 모바일에서는 flex-col, 데스크톱에서는 flex-row로 변경 */}
            <div className="flex flex-col md:flex-row md:items-center gap-2">
                <div className="flex-1 flex items-center gap-2">
                    {type === 'unover' ? (
                        <>
                            {/* 오버 배당 */}
                            <OddsButton
                                name="오버"
                                value={odds.oddsAwayValue}
                                onClick={() => handleOddsClick(match, odds, 'away')}
                                type={type}
                                position="left"
                            />

                            {/* 기준점 */}
                            <DrawButton
                                value={null}
                                oddsBase={odds.oddsBase}
                                type={type}
                            />

                            {/* 언더 배당 */}
                            <OddsButton
                                name="언더"
                                value={odds.oddsHomeValue}
                                onClick={() => handleOddsClick(match, odds, 'home')}
                                type={type}
                                position="right"
                            />
                        </>
                    ) : (
                        <>
                            {/* 기존 승무패/핸디캡 배당 코드 유지 */}
                            <OddsButton
                                name={match.home_name}
                                value={odds.oddsHomeValue}
                                onClick={() => handleOddsClick(match, odds, 'home')}
                                type={type}
                                position="left"
                            />

                            <DrawButton
                                value={odds.oddsDrawValue}
                                oddsBase={odds.oddsBase}
                                type={type}
                                onClick={() => handleOddsClick(match, odds, 'draw')}
                            />

                            <OddsButton
                                name={match.away_name}
                                value={odds.oddsAwayValue}
                                onClick={() => handleOddsClick(match, odds, 'away')}
                                type={type}
                                position="right"
                            />
                        </>
                    )}
                </div>

                {/* 더보기 버튼 - 기본 배당에만 표시하고 추가 배당에는 빈 공간 유지 */}
                <div className="w-full md:w-24">
                    {type === 'basic' && (match.handyOddsList?.length > 0 || match.unoverOddsList?.length > 0) && (
                        <button 
                            className="w-full min-h-[42px] px-3 text-sm text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-lg transition-colors"
                            onClick={() => toggleMatchExpand(match.id)}
                        >
                            {expandedMatches[match.id] ? '접기' : '더보기'}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );

    const getSportEmoji = (sport) => {
        switch(sport?.toLowerCase()) {
            case 'soccer':
            case '축구':
                return '⚽';
            case 'basketball':
            case '농구':
                return '🏀';
            case 'baseball':
            case '야구':
                return '⚾';
            case 'volleyball':
            case '배구':
                return '🏐';
            case 'tabletennis':
            case '탁구':
                return '🏓';
            case 'icehockey':
            case '하키':
                return '🏒';
            case 'football':
            case 'americanfootball':
            case '미식축구':
                return '🏈';
            case 'boxing':
            case 'UFC/복싱':
                return '🥊';
            case 'handball':
            case '핸드볼':
                return '🤾';
            default:
                return '';
        }
    };

    return (
        <div className="flex flex-col h-full bg-white shadow-sm rounded-lg">
            {/* 상단 선택 영역 */}
            <div className="flex justify-between p-3 md:p-4 border-b">
                <div className="flex items-center gap-2">
                    <span className="flex items-center gap-1">
                        {getSportEmoji(name)}
                        <span className="font-bold">{name}</span>
                    </span>
                </div>
            </div>

            {loading && <Loading />}
            
            <div className="flex flex-1 flex-col overflow-y-auto min-h-[calc(100vh-200px)]">
                {Object.keys(contentsList).length > 0 ? (
                    Object.entries(contentsList).map(([key, league]) => {
                        // 시간과 market_name으로 그룹화
                        const timeMarketGroups = league.matches.reduce((acc, match) => {
                            const time = match.time;
                            const marketName = match.oddsList?.[0]?.marketName || '';
                            const groupKey = `${time}_${marketName}`;
                            
                            if (!acc[groupKey]) {
                                acc[groupKey] = [];
                            }
                            acc[groupKey].push(match);
                            return acc;
                        }, {});

                        return (
                            <div key={key} className="border-b last:border-b-0">
                                {/* 리그 헤더 */}
                                <div className="flex items-center gap-2 bg-gray-50 p-2 md:p-3">
                                    <span className="flex items-center gap-2">
                                        {getSportEmoji(league.matches[0]?.sport)}
                                        <img src={league.cc_image} alt="" className="w-4 h-4 md:w-5 md:h-5"/>
                                    </span>
                                    <span className="text-sm md:text-base font-bold truncate text-gray-700">
                                        {league.cc_kr} - {league.league_name}
                                    </span>
                                </div>

                                {/* 매치 목록 */}
                                <div className="divide-y divide-gray-100">
                                    {Object.entries(timeMarketGroups).map(([groupKey, matches]) => (
                                        matches.map((match, index) => (
                                            <div key={match.id}>
                                                {match.oddsList?.[0] && renderMatchRow(
                                                    match,
                                                    match.oddsList[0],
                                                    'basic',
                                                    index === 0 // 같은 시간/마켓 그룹의 첫 번째 매치에만 표시
                                                )}
                                                
                                                {/* 확장된 배당 정보 */}
                                                {expandedMatches[match.id] && (
                                                    <div className="border-t border-gray-100 bg-gray-50">
                                                        {/* 핸디캡 배당 그룹 */}
                                                        {match.handyOddsList?.length > 0 && (
                                                            Object.entries(match.handyOddsList.reduce((groups, odds) => {
                                                                // id를 포함한 그룹 키 생성
                                                                const key = `${odds.id || odds.marketName}`;
                                                                if (!groups[key]) {
                                                                    groups[key] = [];
                                                                }
                                                                groups[key].push(odds);
                                                                return groups;
                                                            }, {})).map(([key, oddsList]) => {
                                                                // oddsBase 기준으로 정렬
                                                                const sortedOddsList = [...oddsList].sort((a, b) => {
                                                                    const baseA = parseFloat(a.oddsBase || '0');
                                                                    const baseB = parseFloat(b.oddsBase || '0');
                                                                    return baseA - baseB;
                                                                });

                                                                return sortedOddsList.map((odds, idx) => (
                                                                    <div key={`handy-${key}-${idx}`}>
                                                                        {renderMatchRow(match, odds, 'handy', idx === 0)}
                                                                    </div>
                                                                ));
                                                            })
                                                        )}
                                                        
                                                        {/* 언더오버 배당 그룹 */}
                                                        {match.unoverOddsList?.length > 0 && (
                                                            Object.entries(match.unoverOddsList.reduce((groups, odds) => {
                                                                // id를 포함한 그룹 키 생성
                                                                const key = `${odds.id || odds.marketName}`;
                                                                if (!groups[key]) {
                                                                    groups[key] = [];
                                                                }
                                                                groups[key].push(odds);
                                                                return groups;
                                                            }, {})).map(([key, oddsList]) => {
                                                                // oddsBase 기준으로 정렬
                                                                const sortedOddsList = [...oddsList].sort((a, b) => {
                                                                    const baseA = parseFloat(a.oddsBase || '0');
                                                                    const baseB = parseFloat(b.oddsBase || '0');
                                                                    return baseA - baseB;
                                                                });

                                                                return sortedOddsList.map((odds, idx) => (
                                                                    <div key={`unover-${key}-${idx}`}>
                                                                        {renderMatchRow(match, odds, 'unover', idx === 0)}
                                                                    </div>
                                                                ));
                                                            })
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        ))
                                    ))}
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <div className="flex flex-1 items-center justify-center p-4">
                        <div className="text-center p-6 md:p-8 bg-gray-50 rounded-lg border border-gray-200">
                            <p className="text-gray-600 font-medium">진행중인 경기가 없습니다.</p>
                            <p className="text-gray-400 text-sm mt-1">다른 종목을 선택해주세요.</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

// React.memo를 사용하여 필요한 props가 변경될 때만 리렌더링
export default React.memo(FreeMatch);