import {useEffect, useState} from "react";
import WidgetGameScheduleRow from "./WidgetGameScheduleRow";

const WidgetGameSchedule = (props) => {
    const [isPlaying, setIsPlaying] = useState(true)
    const [sports, setSports] = useState([])

    useEffect(() => {
        let filterList;
        if (isPlaying) {
            filterList = props.list.filter(item => item.status_id === 0 || item.status_id === 1)
        }
        else {
            filterList = props.list.filter(item => item.status_id === 2)
        }
        setSports(filterList)
    }, [props, isPlaying])

    const toggle = (value) => {
        setIsPlaying(value)
    }

    return (
        <div className={"w-full h-[300px] rounded-xl border border-gray-200 bg-white p-2 md:p-3 shadow-sm flex flex-col"}>
            <div className={"flex-none flex flex-col items-center gap-1"}>
                <span className={"font-bold text-lg md:text-xl text-gray-800"}>경기 일정 및 결과</span>
                <div className={"flex flex-row items-center justify-center"}>
                    <button 
                        className={`w-[120px] py-1 rounded-full text-sm transition-all
                            ${isPlaying 
                                ? "text-white bg-blue-600 z-[2]" 
                                : "text-gray-600 bg-gray-100 z-[1]"}`}
                        onClick={() => toggle(true)}>
                        경기 예정
                    </button>
                    <button 
                        className={`-ml-7 w-[120px] py-1 rounded-full text-sm transition-all
                            ${!isPlaying 
                                ? "text-white bg-blue-600 z-[2]" 
                                : "text-gray-600 bg-gray-100 z-[1]"}`}
                        onClick={() => toggle(false)}>
                        경기종료
                    </button>
                </div>
            </div>
            <div className={"flex-1 flex flex-col mt-4 min-h-0"}>
                <div className={"flex-none grid grid-cols-[100px_100px_1fr] items-center py-2 bg-gray-50 border-y border-gray-200"}>
                    <span className={"text-sm text-gray-600 text-center"}>경기시간</span>
                    <span className={"text-sm text-gray-600 pl-2"}>종목</span>
                    <span className={"text-sm text-gray-600 pl-2"}>매치</span>
                </div>
                <div className={"flex-1 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 min-h-0"}>
                    {sports.map((item, idx) => (
                        <WidgetGameScheduleRow 
                            key={String(idx)} 
                            data={item} 
                            playing={isPlaying} 
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default WidgetGameSchedule