import {useState} from "react";
import WidgetWeekWithdrawRow from "./WidgetWeekWithdrawRow";

const WidgetWeekWithdraw = (props) => {
    return (
        <div className={"w-full h-[300px] rounded-xl border border-gray-200 bg-white p-3 md:p-4 shadow-sm flex flex-col"}>
            {/* 헤더 영역 */}
            <div className={"flex-none flex flex-row justify-between items-center"}>
                <span className={"font-bold text-lg md:text-xl text-gray-800"}>금주의 출금 TOP</span>
            </div>

            {/* 테이블 헤더 */}
            <div className={"flex-none grid grid-cols-[80px_120px_1fr_100px] items-center py-2 mt-4 bg-gray-50 border-y border-gray-200"}>
                <span className={"text-sm text-gray-600 text-center"}>순위</span>
                <span className={"text-sm text-gray-600 text-center"}>시간</span>
                <span className={"text-sm text-gray-600 text-center"}>금액</span>
                <span className={"text-sm text-gray-600 text-center"}>회원</span>
            </div>

            {/* 스크롤 영역 */}
            <div className={"flex-1 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 min-h-0"}>
                {props.list.map((item, idx) => (
                    <WidgetWeekWithdrawRow 
                        key={item.seq} 
                        data={item} 
                        index={idx}
                    />
                ))}
            </div>
        </div>
    )
}

export default WidgetWeekWithdraw