import {changeSportName} from "../../biz/Betting";

const WidgetGameScheduleRow = (props) => {
    const {playing} = props
    const {time, sport, home_name, away_name} = props.data

    return (
        <div className={"grid grid-cols-[100px_100px_1fr] items-center py-2 border-b border-gray-100 hover:bg-gray-50 transition-colors"}>
            {/* 경기 시간 */}
            <span className={"text-sm text-gray-600 text-center"}>
                {time.slice(11, 16)}
            </span>

            {/* 종목 및 상태 */}
            <div className={"flex flex-row items-center gap-1 pl-2 overflow-hidden"}>
                <span className={"text-sm text-gray-700 font-medium truncate"}>
                    {changeSportName(sport)}
                </span>
                {!playing && (
                    <span className={"rounded-full bg-gray-100 px-1.5 py-0.5 text-xs text-gray-600 flex-shrink-0"}>
                        종료
                    </span>
                )}
            </div>

            {/* 팀 정보 */}
            <div className={"flex flex-row items-center gap-2 pl-2"}>
                <span className={"text-sm text-gray-700 truncate"}>{home_name}</span>
                <span className={"text-sm text-blue-500 font-bold flex-shrink-0"}>vs</span>
                <span className={"text-sm text-gray-700 truncate"}>{away_name}</span>
            </div>
        </div>
    )
}

export default WidgetGameScheduleRow