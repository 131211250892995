import {accountActions} from "../../../redux/actions/account";
import {connect} from "react-redux";
import {useState} from "react";
import sha256 from "sha256";
import * as api from "../../../api/api";
import {useNavigate} from "react-router-dom";
import Loading from "../../../component/Loading";

const UserLogout = (props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [loginInfo, setLoginInfo] = useState({
        userId: "", password: ""
    })

    const login = async () => {
        const {userId, password} = loginInfo;
        if (userId.length === 0 || password.length === 0) {
            return;
        }

        const params = {
            userId: userId,
            password: password
        }

        setLoading(true);
        try {
            const result = await api.login(params);
            const {data, status, statusText} = result;
            
            if (status === 200) {
                if (Object.keys(data).length > 0) {
                    if (data.useYn === 1) {
                        if (data.status === 9) {
                            window.alert("회원 가입 대기상태 입니다. 고객센터에 문의하세요.")
                            return;
                        }
                        else if (data.status === 2 || data.status === 3) {
                            window.alert("사용할 수 없는 계정입니다. 고객센터에 문의하세요.")
                            return;
                        }

                        if (data.blockYn === 1) {
                            window.alert("사용할 수 없는 계정입니다. 고객센터에 문의하세요.")
                            return;
                        }
                        
                        // 로그인 성공 처리 순서 변경
                        await props.setLogin(true, data);  // 로그인 상태와 사용자 데이터를 함께 전달
                        props.onMenu(0);                   // 메뉴 변경
                    } else {
                        window.alert("사용할 수 없는 계정입니다. 고객센터에 문의하세요.")
                    }
                } else {
                    window.alert("ID와 패스워드를 다시 확인해주세요.")
                }
            } else {
                window.alert(statusText);
            }
        } catch (ex) {
            window.alert(ex.message);
        } finally {
            setLoading(false);
        }
    }

    const register = () => {
        navigate("/register")
    }

    return (
        <div className={"w-full bg-white flex flex-row justify-center"}>
            <div className={"w-full max-w-[1280px] flex flex-col md:flex-row items-center justify-end p-2 px-1 gap-2 border-b border-gray-200"}>
                {loading ? <Loading /> : null}
                <div className={"flex flex-row items-center gap-2 w-full md:w-auto justify-between md:justify-end"}>
                    <input className={"flex-1 md:flex-none md:w-32 px-2 h-8 rounded-md bg-white border border-gray-300 text-gray-700 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"}
                           onChange={event => {
                               setLoginInfo((state) => ({
                                   ...state, userId: event.target.value
                               }))
                           }}
                           onKeyPress={(e) => {
                               if (e.key === "Enter") {
                                   login()
                               }
                           }}
                           placeholder={"아이디"}/>
                    <input className={"flex-1 md:flex-none md:w-32 px-2 h-8 rounded-md bg-white border border-gray-300 text-gray-700 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"}
                           type={"password"}
                           onChange={event => {
                               setLoginInfo((state) => ({
                                   ...state, password: event.target.value
                               }))
                           }}
                           onKeyPress={(e) => {
                               if (e.key === "Enter") {
                                   login()
                               }
                           }}
                           placeholder={"비밀번호"}/>
                </div>
                <div className={"flex flex-row items-center gap-2 w-full md:w-auto justify-between md:justify-end"}>
                    <button className={"flex-1 md:flex-none h-8 px-4 rounded-md text-white text-sm bg-blue-600 hover:bg-blue-700 transition-colors duration-200"}
                            onClick={login}>로그인</button>
                    <button className={"flex-1 md:flex-none h-8 px-4 rounded-md text-gray-700 text-sm border border-gray-300 hover:bg-gray-50 transition-colors duration-200"}
                            onClick={register}>회원가입</button>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        setAccount: (account) => {
            dispatch(accountActions.setAccount(account));
        },
        dispatch
    }
}

export default connect(null, mapDispatchToProps)(UserLogout)